/* Custom Properties */
:root {
    /* Colors */
    --color-primary: #392f2f;
    --color-primary-dark: #2a2424;
    --amplify-internal-button-background-color: #2a2424;
    --amplify-internal-button-color: #ffffff;
    --color-success: #22c55e;
    --color-warning: #eab308;
    --color-danger: #ef4444;
    --color-text: #1f2937;
    --color-text-light: #6b7280;
    --color-background: #f9fafb;
    --color-white: #ffffff;
    --color-border: #e5e7eb;

    /* Status Colors */
    --status-placed: #fbbf24;
    --status-confirmed: #34d399;
    --status-delivered: #60a5fa;
    --status-transit: hsl(161, 63%, 72%);
    --status-cancelled: #f87171;

    /* Spacing */
    --spacing-xs: 0.5rem;
    --spacing-sm: 1rem;
    --spacing-md: 1.5rem;
    --spacing-lg: 2rem;
    --spacing-xl: 3rem;

    /* Border Radius */
    --radius-sm: 0.375rem;
    --radius-md: 0.5rem;
    --radius-lg: 0.75rem;

    /* Shadows */
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);

    /* Container Max Widths */
    --container-max-width: min(100% - 2rem, 1400px);
}

/* Reset */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.amplify-button--primary {
    background-color: var(--color-primary-dark) !important;
    /* color: white !important; */
}


/* Base Styles - Mobile First */
html {
    font-family: 'Inter', system-ui, sans-serif;
    line-height: 1.5;
    color: var(--color-text);
    background-color: var(--color-background);
    -webkit-text-size-adjust: 100%;
}

body {
    min-height: 100vh;
    display: grid;
}

#root {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

h1,
h2,
h3 {
    font-family: 'Space Grotesk', sans-serif;
    line-height: 1.2;
}

h1 {
    font-size: clamp(1.5rem, 5vw, 2rem);
}

h2 {
    font-size: clamp(1.25rem, 4vw, 1.5rem);
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

/* Modal Styles */
.modal {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1.5rem;
}

.modal-content {
    background: var(--color-white);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-lg);
    width: min(calc(100% - 2rem), 600px);
    max-height: min(calc(100vh - 2rem), 800px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.modal-header {
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-sm);
}

.modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--color-text);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
}

.modal-close {
    background: none;
    border: none;
    color: var(--color-text-light);
    cursor: pointer;
    padding: var(--spacing-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-md);
    transition: all 0.2s ease;
}

.modal-close:hover {
    background: var(--color-background);
    color: var(--color-text);
}

.modal-body {
    padding: var(--spacing-md);
    overflow-y: auto;
}

.modal-form {
    display: grid;
    gap: var(--spacing-md);
}

.form-group {
    display: grid;
    gap: var(--spacing-xs);
}

.form-group-row {
    display: grid;
    grid-template-columns: 8rem 1fr;
    align-items: start;
    gap: var(--spacing-md);
}

@media (max-width: 640px) {
    .form-group-row {
        grid-template-columns: 1fr;
        gap: var(--spacing-xs);
    }
}

.form-label {
    font-weight: 500;
    color: var(--color-text);
    font-size: 0.875rem;
}

.form-label.required::after {
    content: "*";
    color: var(--color-danger);
    margin-left: 0.25rem;
}

.form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-md);
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--color-text);
    transition: all 0.2s ease;
}

.form-control:hover {
    border-color: var(--color-primary);
}

.form-control:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px color-mix(in srgb, var(--color-primary) 25%, transparent);
}

.form-control.price-input {
    padding-left: 1.5rem;
}

.price-wrapper {
    position: relative;
}

.price-symbol {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-text-light);
    font-size: 0.875rem;
    pointer-events: none;
}

textarea.form-control {
    min-height: 100px;
    resize: vertical;
}

.modal-footer {
    padding: var(--spacing-md);
    border-top: 1px solid var(--color-border);
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-sm);
}


/* Header - Mobile First */
.app-header {
    background: var(--color-white);
    border-bottom: 1px solid var(--color-border);
    padding: var(--spacing-sm);
}

.app-header nav {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.nav-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    list-style: none;
    width: 100%;
}

.nav-list a {
    color: var(--color-text-light);
    text-decoration: none;
    font-weight: 500;
    padding: var(--spacing-xs);
    border-radius: var(--radius-md);
    transition: all 0.2s ease;
    display: block;
    text-align: center;
}

.nav-list a:hover,
.nav-list a.active {
    color: var(--color-primary);
    background: color-mix(in srgb, var(--color-primary) 10%, transparent);
}

.logout-button {
    color: var(--color-text);
    text-decoration: none;
    font-weight: 500;
    padding: var(--spacing-xs);
    border-radius: var(--radius-md);
    background: var(--color-background);
    transition: all 0.2s ease;
    text-align: center;
    display: block;
}

.logout-button:hover {
    background: var(--color-border);
}

.header-content {
    padding: var(--spacing-md) var(--spacing-xs);
    text-align: center;
}

/* Main Content - Mobile First */
main {
    padding: var(--spacing-sm);
    margin: 0 auto;
    width: var(--container-max-width);
    display: grid;
    gap: var(--spacing-lg);
}

/* Metrics Grid - Mobile First */
.metrics-grid {
    display: grid;
    gap: var(--spacing-sm);
    margin-top: var(--spacing-md);
}

.metric {
    background: var(--color-white);
    padding: var(--spacing-md);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-sm);
}

.metric dt {
    color: var(--color-text-light);
    font-size: 0.875rem;
    margin-bottom: var(--spacing-xs);
}

.metric dd {
    font-family: 'Space Grotesk', sans-serif;
    font-size: clamp(1.5rem, 4vw, 1.875rem);
    font-weight: 700;
    margin-bottom: var(--spacing-xs);
}

.trend {
    font-size: 0.875rem;
    font-weight: 500;
}

.trend.positive {
    color: var(--color-success);
}

.trend.negative {
    color: var(--color-danger);
}

.trend.neutral {
    color: var(--color-text-light);
}

/* Section Headers - Mobile First */
.section-header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    align-items: center;
    margin-bottom: var(--spacing-md);
    text-align: center;
}

.view-all {
    color: var(--color-primary);
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
}

/* Orders Table - Mobile First */
.orders-table {
    width: 100%;
    background: var(--color-white);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-sm);
    font-size: 0.875rem;
}

.orders-table thead {
    display: none;
    /* Hide headers on mobile */
}

.orders-table tr {
    display: block;
    padding: var(--spacing-sm);
    border-bottom: 1px solid var(--color-border);
}

.orders-table td {
    display: grid;
    grid-template-columns: 40% 60%;
    padding: var(--spacing-xs) 0;
}

.orders-table td::before {
    content: attr(data-label);
    font-weight: 500;
    color: var(--color-text-light);
}

.status {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border-radius: 2rem;
    font-size: 0.875rem;
    font-weight: 500;
    justify-content: center;
}

.status.placed {
    background: color-mix(in srgb, var(--color-warning) 15%, transparent);
    color: var(--color-warning);
}

.status.confirmed {
    background: color-mix(in srgb, var(--color-success) 15%, transparent);
    color: var(--color-success);
}

.status.delivered {
    background: color-mix(in srgb, var(--color-primary) 15%, transparent);
    color: var(--color-primary);
}

.status.transit {
    background: color-mix(in srgb, var(--color-primary-dark) 15%, transparent);
    color: var(--color-primary-dark);
}

.status.cancelled {
    background: color-mix(in srgb, var(--color-danger) 15%, transparent);
    color: var(--color-danger);
}

/* Items Grid - Mobile First */
.items-grid {
    display: grid;
    gap: var(--spacing-md);
}

.menu-item {
    background: var(--color-white);
    border-radius: var(--radius-lg);
    overflow: hidden;
    box-shadow: var(--shadow-sm);
}

.menu-item img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.menu-item h3 {
    padding: var(--spacing-sm);
    font-size: 1.125rem;
}

.menu-item .price {
    padding: 0 var(--spacing-sm);
    color: var(--color-primary);
    font-weight: 600;
}

.menu-item .orders {
    padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-sm);
    color: var(--color-text-light);
    font-size: 0.875rem;
}


/* Orders Page Specific Styles */
.orders-container {
    padding: var(--spacing-md);
    max-width: var(--container-max-width);
    margin: 0 auto;
}

.orders-header {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-lg);
    align-items: center;
    justify-content: space-between;
}

.orders-actions {
    display: flex;
    gap: var(--spacing-sm);
    flex-wrap: wrap;
}

.orders-filters {
    display: flex;
    gap: var(--spacing-sm);
    flex-wrap: wrap;
}

.filter-group {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
}

.filter-label {
    font-weight: 500;
    color: var(--color-text);
    font-size: 0.875rem;
}

.filter-select {
    min-width: 150px;
    padding: 0.5rem;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-md);
    font-size: 0.875rem;
    color: var(--color-text);
    background-color: var(--color-white);
    cursor: pointer;
}

.filter-select:hover {
    border-color: var(--color-primary);
}

.filter-select:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px color-mix(in srgb, var(--color-primary) 25%, transparent);
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-md);
    background: var(--color-white);
    color: var(--color-text);
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-icon:hover {
    border-color: var(--color-primary);
    color: var(--color-primary);
    background: color-mix(in srgb, var(--color-primary) 5%, transparent);
}

.btn-icon svg {
    width: 1.25rem;
    height: 1.25rem;
}

.btn-primary {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--radius-md);
    background: var(--color-primary);
    color: var(--color-white);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-primary:hover {
    background: var(--color-primary-dark);
}

.btn-primary svg {
    width: 1.25rem;
    height: 1.25rem;
}

/* Order List */
.orders-list {
    display: grid;
    gap: var(--spacing-md);
}

/* Collapsible Order Card */
.order-card {
    background: var(--color-white);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-sm);
    transition: box-shadow 0.2s ease;
}

.order-card:hover {
    box-shadow: var(--shadow-md);
}

.order-card-header {
    padding: var(--spacing-md);
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
    gap: var(--spacing-md);
    cursor: pointer;
    user-select: none;
}

@media (max-width: 768px) {
    .order-card-header {
        grid-template-columns: 1fr auto;
        gap: var(--spacing-sm);
    }

    .order-card-header .order-amount {
        grid-column: 1 / -1;
    }

    .order-card-header .order-status {
        grid-column: 1 / 2;
    }

    .order-card-header .expand-button {
        grid-column: 2 / 3;
    }
}

.order-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--color-text);
    margin: 0;
}

.order-amount {
    font-weight: 500;
    color: var(--color-primary);
}

.order-status {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border-radius: var(--radius-full);
    font-size: 0.875rem;
    font-weight: 500;
}

.expand-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-md);
    background: transparent;
    color: var(--color-text);
    cursor: pointer;
    transition: all 0.2s ease;
}

.expand-button:hover {
    background: color-mix(in srgb, var(--color-primary) 5%, transparent);
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.expand-button svg {
    width: 1.25rem;
    height: 1.25rem;
    transition: transform 0.2s ease;
}

.order-card.expanded .expand-button svg {
    transform: rotate(180deg);
}

.order-card-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    border-top: 1px solid transparent;
}

.order-card.expanded .order-card-content {
    max-height: 1000px;
    border-top-color: var(--color-border);
    transition: max-height 0.5s ease-in;
}

.order-details {
    padding: var(--spacing-md);
}


/* Status Colors */
.status-placed {
    background: color-mix(in srgb, var(--status-placed) 15%, transparent);
    color: color-mix(in srgb, var(--status-placed) 85%, black);
}

.status-confirmed {
    background: color-mix(in srgb, var(--status-confirmed) 15%, transparent);
    color: color-mix(in srgb, var(--status-confirmed) 85%, black);
}

.status-completed {
    background: color-mix(in srgb, var(--status-completed) 15%, transparent);
    color: color-mix(in srgb, var(--status-completed) 85%, black);
}

.status-cancelled {
    background: color-mix(in srgb, var(--status-cancelled) 15%, transparent);
    color: color-mix(in srgb, var(--status-cancelled) 85%, black);
}

.order-meta {
    display: flex;
    gap: var(--spacing-lg);
    margin-bottom: var(--spacing-md);
    flex-wrap: wrap;
}

.order-meta-item {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
}

.meta-label {
    font-size: 0.875rem;
    color: var(--color-text-light);
}

.meta-value {
    font-weight: 500;
    color: var(--color-text);
}

.order-items {
    margin-bottom: var(--spacing-md);
}

.order-items-title {
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-text);
    margin-bottom: var(--spacing-sm);
}

.item-list {
    display: grid;
    gap: var(--spacing-sm);
}

.order-item {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: var(--spacing-md);
    padding: var(--spacing-sm);
    background: color-mix(in srgb, var(--color-primary) 5%, transparent);
    border-radius: var(--radius-md);
}

@media (max-width: 640px) {
    .order-item {
        grid-template-columns: 1fr;
        gap: var(--spacing-xs);
    }
}

.item-name {
    font-weight: 500;
}

.item-quantity {
    color: var(--color-text-light);
}

.item-price {
    font-weight: 500;
    color: var(--color-primary);
}

.order-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

.summary-section {
    background: color-mix(in srgb, var(--color-primary) 5%, transparent);
    padding: var(--spacing-md);
    border-radius: var(--radius-md);
}

.summary-title {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--color-text);
    margin-bottom: var(--spacing-sm);
}

.summary-content {
    display: grid;
    gap: var(--spacing-xs);
}

.summary-item {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
}

.summary-label {
    color: var(--color-text-light);
}

.summary-value {
    font-weight: 500;
    color: var(--color-text);
}

.order-actions {
    display: flex;
    gap: var(--spacing-sm);
    justify-content: flex-end;
    flex-wrap: wrap;
}

.btn {
    padding: 0.5rem 1rem;
    border-radius: var(--radius-md);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-reject {
    background: var(--color-white);
    border: 1px solid var(--color-danger);
    color: var(--color-danger);
}

.btn-reject:hover {
    background: color-mix(in srgb, var(--color-danger) 10%, transparent);
}

.btn-accept {
    background: var(--color-success);
    border: 1px solid var(--color-success);
    color: var(--color-white);
}

.btn-accept:hover {
    background: color-mix(in srgb, var(--color-success) 85%, black);
    border-color: color-mix(in srgb, var(--color-success) 85%, black);
}

/* Menu Page Specific Styles - Mobile First */
form input {
    font-size: 18px !important;
}

.menu-header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-lg);
}

.menu-search {
    position: relative;
    width: 100%;
}

.menu-search input {
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-lg);
    padding-right: 3rem;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-lg);
    font-size: 1rem;
    background: var(--color-white);
}

.menu-search input:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: var(--shadow-sm);
}

.menu-search button {
    position: absolute;
    right: var(--spacing-sm);
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--color-text-light);
    cursor: pointer;
    padding: var(--spacing-xs);
}

.menu-search button:hover {
    color: var(--color-primary);
}

.menu-filters {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-sm);
}

.menu-filters select {
    flex: 1;
    min-width: 150px;
    padding: var(--spacing-sm);
    border: 1px solid var(--color-border);
    border-radius: var(--radius-md);
    background: var(--color-white);
    font-size: 0.875rem;
}

/* Menu List - Mobile First */
.menu-list {
    display: grid;
    gap: var(--spacing-md);
}

.menu-item {
    background: var(--color-white);
    border-radius: var(--radius-lg);
    padding: var(--spacing-md);
    box-shadow: var(--shadow-sm);
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 0;
    /* Enable text truncation */
}

.menu-item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: var(--spacing-sm);
    gap: var(--spacing-sm);
    min-width: 0;
    /* Enable text truncation */
}

.menu-item-title-wrapper {
    min-width: 0;
    /* Enable text truncation */
    flex: 1;
}

.menu-item-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--color-text);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 1.3;
}

.menu-item-actions {
    display: flex;
    gap: var(--spacing-sm);
    flex-shrink: 0;
}

.menu-item-action {
    background: none;
    border: none;
    padding: var(--spacing-xs);
    color: var(--color-text-light);
    cursor: pointer;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
}

.menu-item-action:hover {
    color: var(--color-primary);
}

.menu-item-action.delete:hover {
    color: var(--color-danger);
}

.menu-item-description {
    color: var(--color-text-light);
    font-size: 0.875rem;
    margin-bottom: var(--spacing-sm);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.menu-item-prices {
    display: grid;
    gap: var(--spacing-xs);
    margin-top: auto;
}

.menu-item-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-xs) 0;
    border-bottom: 1px solid var(--color-border);
    font-size: 0.875rem;
    gap: var(--spacing-sm);
}

.menu-item-price:last-child {
    border-bottom: none;
}

.menu-item-price span {
    min-width: 0;
}

.menu-item-price span:first-child {
    color: var(--color-text-light);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-item-price span:last-child {
    font-weight: 600;
    color: var(--color-primary);
    flex-shrink: 0;
}

/* Pagination - Mobile First */
.menu-pagination {
    display: flex;
    justify-content: center;
    gap: var(--spacing-sm);
    margin-top: var(--spacing-xl);
    flex-wrap: wrap;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .orders-header {
        flex-direction: column;
        align-items: stretch;
    }

    .orders-actions,
    .orders-filters {
        width: 100%;
    }

    .filter-select {
        flex: 1;
    }
}

@media (max-width: 480px) {
    .order-actions {
        flex-direction: column;
    }

    .btn {
        width: 100%;
    }
}

/* Footer - Mobile First */
footer {
    background: var(--color-white);
    border-top: 1px solid var(--color-border);
    padding: var(--spacing-md);
    text-align: center;
    color: var(--color-text-light);
    font-size: 0.875rem;
}

/* Tablet Breakpoint (640px) */
@media (min-width: 40em) {
    .app-header {
        padding: var(--spacing-md);
    }

    .nav-list {
        flex-direction: row;
        justify-content: center;
    }

    .section-header {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }

    .metrics-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: var(--spacing-md);
    }

    .items-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .menu-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Desktop Breakpoint (1024px) */
@media (min-width: 64em) {
    .app-header nav {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .nav-list {
        width: auto;
    }

    .header-content {
        text-align: left;
        padding: var(--spacing-md) 0;
    }

    main {
        padding: var(--spacing-lg);
    }

    .metrics-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .orders-table thead {
        display: table-header-group;
    }

    .orders-table tr {
        display: table-row;
    }

    .orders-table th,
    .orders-table td {
        display: table-cell;
        padding: var(--spacing-sm) var(--spacing-md);
        text-align: left;
    }

    .orders-table td::before {
        display: none;
    }

    .items-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .menu-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Large Desktop Breakpoint (1280px) */
@media (min-width: 80em) {
    .items-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .menu-list {
        grid-template-columns: repeat(4, 1fr);
    }
}